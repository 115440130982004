import { Link } from 'gatsby'
import React from 'react'
import { Button } from 'react-bootstrap'

type TagItemProps = {
  tag?: string
}

const Component: React.FC<TagItemProps> = ({ tag }) => {
  return (
    <Link to={`/tags/${tag}/`}>
      <Button
        variant="outline-primary"
        size="sm"
      >
        #{tag}
      </Button>
    </Link>
  )
}

export default Component