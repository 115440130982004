import React from 'react'

import TagList from '../TagList'

type BlogPostHeaderProps = {
  title: GatsbyTypes.Maybe<string>
  tags: GatsbyTypes.Maybe<readonly GatsbyTypes.Maybe<string>[]>
  date: GatsbyTypes.Maybe<string>
}

const Component: React.FC<BlogPostHeaderProps> = ({ title, tags, date }) => {
  return (
    <header>
      <h1 itemProp="headline">{title}</h1>
      <TagList tags={tags} />
      <p>{date}</p>
    </header>
  )
}
    
export default Component
