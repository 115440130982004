import React from 'react'

import TagItem from '../TagItem'

type TagListProps = {
  tags: GatsbyTypes.Maybe<readonly GatsbyTypes.Maybe<string>[]>
}

const Component: React.FC<TagListProps> = ({ tags }) => {
  return (
    <small>{
      tags?.map(tag => (
        <TagItem tag={tag} key={tag} />
      ))
    }</small>
  )
}

export default Component